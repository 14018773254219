// Initialize Dashboard namespace
var Fx = (window.Fx = window.Fx || {});

/**
 * Dashboard Meetings Module
 */
Fx.Preconditions = (function () {
    /**
     * Call Module constructor
     *
     * @constructor
     */
    function Preconditions() {}

    /**
     * Checks on dependencies and outputs warning if they are not set in window.Dashboard
     *
     * example usage:
     *
     * Dashboard.Preconditions.check([
     *    'Data',
     *    'Data.MeetingRequests',
     *    'Data.UpcomingMeetings',
     *    'Data.Templates',
     *    'Data.Templates.MeetingRequest',
     *    'Data.Templates.UpcomingDay',
     *    'Data.Templates.UpcomingMeeting'
     * ]);
     */
    Preconditions.prototype.check = function (conditions, subject) {
        /**
         * Resolves Path with dot notation and returns content
         *
         * @param obj
         * @param path
         * @returns mixed
         */
        var resolve = function (obj, path) {
            return path.split('.').reduce((prev, curr) => {
                return typeof prev === 'object' ? prev[curr] : undefined;
            }, obj || self);
        };

        for (var i = 0; i < conditions.length; i++) {
            if (resolve(subject, conditions[i]) === undefined) {
                console.warn(
                    'Precondition failed. Looks like ' +
                        conditions[i] +
                        ' was not defined. Subject: ',
                    subject,
                );
            }
        }
    };

    // Return as singleton instance
    return new Preconditions();
})();

export default Fx.Preconditions;
